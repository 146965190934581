import {InjectionToken} from '@angular/core';
import {IAppConfig} from './app-config.interface';

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');

export const AppConfig: IAppConfig = {
  version: 'v2.0.0',

  // https://moment.github.io/luxon/#/parsing?id=table-of-tokens
  dateTimeFormat: 'FF',
  dateFormat: 'DD',

  dateTimeFormatMoment: 'MMM DD, Y HH:mm:ss',
  timezone: 'Europe/London',
};
