<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <p>{{ message }}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="yes()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="no()">No</button>
</div>
