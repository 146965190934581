import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class ErrorService {

  /**
   * API Errors
   * Follows our API Error standard of error objects
   */
  public apiErrors: Array<any> = [];

  /**
   * General Errors
   * For example, Laravel 422 form validation is just array of fieldname/validation error
   */
  public errors = {};

  private hideTimeout = null;

  /**
   * Add error (typically meant for Laravel form validation style of key value)
   * @param key
   * @param value
   */
  addError(key: string, value: Array<string>): void {
      this.errors[key] = value;
  }


  addApiError(error): void {
    this.apiErrors.push(error);
  }


  /**
   * Handle 422 Form Validation Errors
   * @param err
   */
  handleValidationErrors(err: HttpErrorResponse) {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }

    this.hideTimeout = setTimeout(() => this.clearErrors(), 8000);

    if (err.status == 422) {
      if (err.error.hasOwnProperty('errors')) {
        for (const field in err.error.errors) {
          // const fieldErrors = err.error.errors[field].join('<br>');
          this.addError(field, err.error.errors[field]);
        }
      } else {
        this.errors = err.error;
      }
    }
  }


  clearErrors() {
    this.errors = {};
    this.apiErrors = [];
  }

}
