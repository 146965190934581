import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

/**
 * PageTitleService
 *
 * Wraps Angulars Title Service, but uses our app config for consistency page title format
 */

@Injectable({providedIn: 'root'})
export class PageTitleService {
  constructor(
    private titleService: Title
  ) {}

  getTitle() {
    return this.titleService.getTitle();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} - RSDT CRM`);
  }
}
